//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { PlaceModel } from '@/modules/place/place-model';

const { fields } = PlaceModel;
const formSchema = new FormSchema([
  fields.notifications,
]);

export default {
  name: 'app-place-notification-form-page',
  props: ['id'],

  async created() {
    if (this.isEditing) {
      await this.doFindPlace(this.id);
    } 
    await this.doFindSettings();
    const languages = require('language-list')();
    this.notificationLang = this.settings['notificationLanguage'].map(languageCode => {
      return {
        value: languageCode,
        label: languages.getLanguageName(languageCode),
      }
    })
    this.tab = this.notificationLang[0].value
    this.model = []
    if (this.record && this.record['notifications']) {
      this.notificationLang.forEach(lang => {
        this.model.push({
          lang: lang.value,
          title: this.record.notifications[lang.value] ? this.record.notifications[lang.value].title : null,
          message: this.record.notifications[lang.value] ? this.record.notifications[lang.value].message : null,
        })
      });
    } else {
      this.notificationLang.forEach(lang => {
        this.model.push({
          lang: lang.value,
          title: null,
          message: null,
        })
      });
    }
  },

  data() {
    return {
      rules: formSchema.rules(),
      tab: '',
      notificationLang: [],
      model: null,
      image: [],
    };
  },
  computed: {
    ...mapGetters({
      record: 'place/form/record',
      findLoading: 'place/form/findLoading',
      saveLoading: 'place/form/saveLoading',
      settings: 'settings/settings',
      findSettingLoading: 'settings/findLoading',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    fields() {
      return fields;
    },
    isEditing() {
      return this.$route.path.includes('/edit')
    },
    isRTL() {
      return this.currentLanguageCode == 'ar'
    },
  },

  methods: {
    ...mapActions({
      doFindPlace: 'place/form/doFind',
      doNew: 'place/form/doNew',
      doUpdate: 'place/form/doUpdate',
      doCreate: 'place/form/doCreate',
      doCreateOrUpdatePlaceNotification: 'place/form/doCreateOrUpdatePlaceNotification',
      doFindSettings: 'settings/doFind',
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
    async doSubmit() {
      const id = this.id 
      let values = {}
      this.model.forEach(element => {
        values[element.lang] = {
          title: element.title,
          message: element.message,
        }
      });

      // this.model.placeId = placeDetails[0].id;
      // const { id, ...values } = formSchema.cast(this.model);
      await this.doCreateOrUpdatePlaceNotification({
        id,
        values
      })
    },
  },
};
